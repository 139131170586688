import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from 'moment';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PhoneIcon from '@mui/icons-material/Phone';
import Link2 from '@mui/material/Link';
import EmailIcon from '@mui/icons-material/Email';
import CakeIcon from '@mui/icons-material/Cake';
import Avatar from '@mui/material/Avatar';

import Title from '../../components/Title';
import { mergeJSON } from '../../helpers/json-utils';
import { stringAvatar, } from "../../helpers/string-utils";
import { getDepartment, saveDepartment } from '../../services/depratments';
import DivisionList from '../Divisions/list';
import RegionList from '../Regions/list';
import AreaList from '../Areas/list';
import Divider from '@mui/material/Divider';

const emptyData = {
    id: '',
    code: '',
    name: '',
    address: '',
    municipality: '',
    province: '',
    contactNumber: '',
    email: '',
    bankName: '',
    bankAccountNumber: '',
    bankBalance: 0.0,
};

export default function Department() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [originalData, setOriginalData] = useState(emptyData);
    const [department, setDepartment] = useState(emptyData);
    const [readOnly, setReadOnly] = useState(false);

    const [openMessage, setOpenMessage] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');

    useLayoutEffect(() => {
        if (!id) return;

        setLoading(true);
        getDepartment({ id }).then((res) => {
            const data = mergeJSON(emptyData, {
                ...res.data,
                bankBalance: parseFloat(res.data.bankBalance || 0).toFixed(2),
            });
            setDepartment(data);
            setOriginalData(data);
            setReadOnly(true);
            setLoading(false);
        });
    }, [id]);

    const popUpMessage = (isOk, message) => {
        setSuccess(isOk);
        setMessage(message);
        setOpenMessage(true);
    }

    const save = () => {
        setLoading(true);
        saveDepartment({ department }).then((res) => {
            setLoading(false);
            setDepartment(res.data);
            setOriginalData(res.data);
            popUpMessage(true, 'Successfully saved department details.');
            setReadOnly(true);
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    };

    const updateField = (fieldName, value) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string') ? value.toUpperCase(): value;
        setDepartment(b => ({
            ...b,
            ...dirty
        }));
    };

    const cancel = async () => {
        setDepartment(originalData);
        setReadOnly(true);
    }

    return (
        <Paper sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
            <Stack sx={{ ml: -5, mt: -5 }} direction="row" alignItems="center" spacing={1}>
                <IconButton size="large" component={Link} onClick={() => navigate(-1)}>
                  <ArrowBackIcon fontSize="inherit" />
                </IconButton>
            </Stack>
            <Title>Department Details</Title>
            <Box sx={{ mt: 5, minHeight: '60vh' }} component="form">
                <Grid container spacing={3}>
                    <Grid item sm={2}>
                        <TextField required
                            InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            id="code"
                            label="Code"
                            value={department.code}
                            onChange={(e) => updateField('code', e.target.value)}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    <Grid item sm={10}>
                        <TextField required
                            InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            id="name"
                            label="Name"
                            value={department.name}
                            onChange={(e) => updateField('name', e.target.value)}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    <Grid item sm={2}>
                        <TextField id="contactNumber"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            label="Contact #"
                            value={department.contactNumber}
                            onChange={(e) => updateField('contactNumber', e.target.value)}
                            fullWidth
                            variant="standard" />
                    </Grid>
                    <Grid item sm={10}>
                        <TextField id="email"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                            label="Email"
                            type={"email"}
                            value={department.email}
                            onChange={(e) => updateField('email', e.target.value)}
                            fullWidth
                            variant="standard" />
                    </Grid>
                </Grid>
                {department.id &&
                    <Box>
                        <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                            Head of Department
                        </Typography>
                        <TableContainer>
                            <Table stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={50}></TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell width={"25%"}>Start Date</TableCell>
                                        <TableCell width={"12%"}>Contact #</TableCell>
                                        <TableCell width={"18%"}>Email</TableCell>
                                        <TableCell width={"12%"}>Birth Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {department.managerDataList?.length > 0 ?
                                        department.managerDataList.map((manager, idx) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={`manager-${manager.id}`}
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => navigate(`/transactions/employees/${manager.id}`)}>
                                                    <TableCell width={50}>
                                                            {manager.hasPhoto ?
                                                                <Avatar src={`${process.env.REACT_APP_API_URL}/api/employee-photo/thumbnail/${manager.id}?rnd=${new Date().getTime()}`} />
                                                                :
                                                                <Avatar {...stringAvatar(`${manager.personalData.lastName},${[manager.personalData.firstName, manager.personalData.suffix].filter(a => !!a).join(' ')}`)} />
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                {manager.personalData.lastName}, {[manager.personalData.firstName, manager.personalData.suffix].filter(a => !!a).join(' ')} {manager.personalData.middleName?.charAt(0)}
                                                            </Typography>
                                                            {manager.employmentInfoData &&
                                                                <Typography variant="caption">
                                                                    {manager.employmentInfoData.positionData?.shortName || manager.employmentInfoData.positionData?.name} - {manager.employmentInfoData.type}
                                                                </Typography>
                                                            }
                                                        </TableCell>
                                                        <TableCell style={{
                                                            width: "25%",
                                                            minWidth: 350,
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            borderStyle: "border-box" }}>
                                                            {manager.employmentInfoData?.startDate} - {moment(manager.employmentInfoData?.startDate, 'MM/DD/YYYY').fromNow(true)}
                                                        </TableCell>
                                                        <TableCell style={{
                                                            width: "12%",
                                                            minWidth: 150,
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            borderStyle: "border-box" }}>
                                                            {!(!manager.personalData.contactNumber) &&
                                                                <Stack direction="row" alignItems="center" gap={1}>
                                                                    <PhoneIcon />
                                                                    <Link2 href={`tel:${manager.personalData.contactNumber}`}
                                                                        underline="hover"
                                                                        onClick={(e) => e.stopPropagation()}>
                                                                        {manager.personalData.contactNumber}
                                                                    </Link2>
                                                                </Stack>
                                                            }
                                                        </TableCell>
                                                        <TableCell style={{
                                                            width: "18%",
                                                            minWidth: 250,
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            borderStyle: "border-box" }}>
                                                            {!(!manager.personalData.email) &&
                                                                <Stack direction="row" alignItems="center" gap={1}>
                                                                    <EmailIcon />
                                                                    <Link2 href={`mailto:${manager.personalData.email}`}
                                                                        underline="hover"
                                                                        onClick={(e) => e.stopPropagation()}>{manager.personalData.email}</Link2>
                                                                </Stack>
                                                            }
                                                        </TableCell>
                                                        <TableCell style={{
                                                            width: "12%",
                                                            minWidth: 150,
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            borderStyle: "border-box" }}>
                                                            {!(!manager.personalData.birthDate) &&
                                                                <Stack direction="row" alignItems="center" gap={1}>
                                                                    <CakeIcon />
                                                                    <Typography variant="body2">{manager.personalData.birthDate}</Typography>
                                                                </Stack>
                                                            }
                                                        </TableCell>
                                                </TableRow>
                                            );
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                                                No manager found
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Typography variant="h5" align="left" sx={{ mt: 5 }}>
                            Employees
                        </Typography>
                        <TableContainer>
                            <Table stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={50}></TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell width={"25%"}>Start Date</TableCell>
                                        <TableCell width={"12%"}>Contact #</TableCell>
                                        <TableCell width={"18%"}>Email</TableCell>
                                        <TableCell width={"12%"}>Birth Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {department.employees?.length > 0 ?
                                        department.employees.map((employee, idx) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={employee.id}
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => navigate(`/transactions/employees/${employee.id}`)}>
                                                    <TableCell width={50}>
                                                        {employee.hasPhoto ?
                                                            <Avatar src={`${process.env.REACT_APP_API_URL}/api/employee-photo/thumbnail/${employee.id}?rnd=${new Date().getTime()}`} />
                                                            :
                                                            <Avatar {...stringAvatar(`${employee.personalData.lastName},${[employee.personalData.firstName, employee.personalData.suffix].filter(a => !!a).join(' ')}`)} />
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>
                                                            {employee.personalData.lastName}, {[employee.personalData.firstName, employee.personalData.suffix].filter(a => !!a).join(' ')} {employee.personalData.middleName?.charAt(0)}
                                                        </Typography>
                                                        {employee.employmentInfoData &&
                                                            <Typography variant="caption">
                                                                {employee.employmentInfoData.positionData?.shortName || employee.employmentInfoData.positionData?.name} - {employee.employmentInfoData.type}
                                                            </Typography>
                                                        }
                                                    </TableCell>
                                                    <TableCell style={{
                                                        width: "25%",
                                                        minWidth: 350,
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        borderStyle: "border-box" }}>
                                                        {employee.employmentInfoData?.startDate} - {moment(employee.employmentInfoData?.startDate, 'MM/DD/YYYY').fromNow(true)}
                                                    </TableCell>
                                                    <TableCell style={{
                                                        width: "12%",
                                                        minWidth: 150,
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        borderStyle: "border-box" }}>
                                                        {!(!employee.personalData.contactNumber) &&
                                                            <Stack direction="row" alignItems="center" gap={1}>
                                                                <PhoneIcon />
                                                                <Link2 href={`tel:${employee.personalData.contactNumber}`}
                                                                    underline="hover"
                                                                    onClick={(e) => e.stopPropagation()}>
                                                                    {employee.personalData.contactNumber}
                                                                </Link2>
                                                            </Stack>
                                                        }
                                                    </TableCell>
                                                    <TableCell style={{
                                                        width: "18%",
                                                        minWidth: 250,
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        borderStyle: "border-box" }}>
                                                        {!(!employee.personalData.email) &&
                                                            <Stack direction="row" alignItems="center" gap={1}>
                                                                <EmailIcon />
                                                                <Link2 href={`mailto:${employee.personalData.email}`}
                                                                    underline="hover"
                                                                    onClick={(e) => e.stopPropagation()}>{employee.personalData.email}</Link2>
                                                            </Stack>
                                                        }
                                                    </TableCell>
                                                    <TableCell style={{
                                                        width: "12%",
                                                        minWidth: 150,
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        borderStyle: "border-box" }}>
                                                        {!(!employee.personalData.birthDate) &&
                                                            <Stack direction="row" alignItems="center" gap={1}>
                                                                <CakeIcon />
                                                                <Typography variant="body2">{employee.personalData.birthDate}</Typography>
                                                            </Stack>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                                                No records found
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }

                {department.operationsDeptFlag && <>
                    <Divider sx={{margin: '40px 0 20px'}}/>
                    <DivisionList />

                    <Divider sx={{margin: '40px 0 20px'}}/>
                    <RegionList />

                    <Divider sx={{margin: '40px 0 20px'}}/>
                    <AreaList />
                </>}
            </Box>

            <Dialog open={openMessage}>
                <DialogTitle id="alert-dialog-title">
                    {success ? "Success": "Error"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenMessage(!openMessage)} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AppBar position="fixed" color="inherit" sx={{ p: 1, top: 'auto', bottom: 0 }}>
                <Box sx={{ pl: 8, display: 'flex', justifyContent: 'center', minHeight: 40 }}>
                    {!readOnly ?
                        <React.Fragment>
                            <Button onClick={save} variant="contained" startIcon={<SaveIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                Save
                            </Button>
                            {!!id &&
                                <Button onClick={cancel} variant="contained" startIcon={<CancelIcon />} sx={{ ml: 1, minWidth: 140 }}>
                                    Cancel
                                </Button>
                            }
                        </React.Fragment>
                    :
                        <Button onClick={() => setReadOnly(false)} variant="contained" startIcon={<EditIcon />} sx={{ ml: 1, minWidth: 140 }}>
                            Edit
                        </Button>
                    }
                </Box>
            </AppBar>
        </Paper>
    );
}
