import React, { useLayoutEffect, useState } from 'react';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import { saveLeaves, } from '../../../services/employees';
import { mergeJSON } from '../../../helpers/json-utils';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { LeaveTypes } from '../../../constants/enum-types';

const emptyData = {
    id: '',
    vacationLeave: 0,
    sickLeave: 0,
    emergencyLeave: 0,
    maternityLeave: 0,
    paternityLeave: 0,
    parentalLeave: 0,
    specialWomanLeave: 0,
    violenceAgainstWomanLeave: 0
};

export default function LeaveDetails({data, hasLeaveInfo, usedCredits, leaveHistory, gender, popUpMessage, setLoading, onChange}) {
    const [leaves, setLeaves] = useState(emptyData);
    const [readOnly, setReadOnly] = useState(false);

    useLayoutEffect(() => {
        if (!data) return;

        setLeaves(mergeJSON(emptyData, data));
    }, [data]);

    useLayoutEffect(() => {
        setReadOnly(hasLeaveInfo);
    }, [hasLeaveInfo]);

    const updateField = (fieldName, value, type) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string') ? value.toUpperCase(): value;
        if ("float" === type) {
            dirty[fieldName] = parseFloat(value).toFixed(2);
        }
        setLeaves(b => ({
            ...b,
            ...dirty
        }));
    };

    const save = async () => {
        setLoading(true);
        await saveLeaves({
            leaves: leaves
        }).then((res) => {
            setLoading(false);
            onChange(res.data);
            popUpMessage(true, 'Successfully saved leave details.');
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    };

    return (
        <React.Fragment>
            <Box component="form">
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Leave Types</TableCell>
                                <TableCell align="right" width={200}>Credit</TableCell>
                                <TableCell align="right" width={200}>Used</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Annual Leave</TableCell>
                                <TableCell>
                                    <TextField InputLabelProps={{ shrink: true }}
                                               InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                               type="number"
                                               value={leaves.vacationLeave}
                                               inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                               onChange={(e) => updateField("vacationLeave", parseInt(e.target.value))}
                                               fullWidth
                                               variant="standard" />
                                </TableCell>
                                <TableCell align="right">
                                    {usedCredits ? usedCredits.VACATION ?? 0 : ''}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Sick Leave</TableCell>
                                <TableCell>
                                    <TextField InputLabelProps={{ shrink: true }}
                                               InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                               type="number"
                                               value={leaves.sickLeave}
                                               inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                               onChange={(e) => updateField("sickLeave", parseInt(e.target.value))}
                                               fullWidth
                                               variant="standard" />
                                </TableCell>
                                <TableCell align="right">
                                    {usedCredits ? usedCredits.SICK ?? 0 : ''}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Emergency Leave</TableCell>
                                <TableCell>
                                    <TextField InputLabelProps={{ shrink: true }}
                                               InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                               type="number"
                                               value={leaves.emergencyLeave}
                                               inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                               onChange={(e) => updateField("emergencyLeave", parseInt(e.target.value))}
                                               fullWidth
                                               variant="standard" />
                                </TableCell>
                                <TableCell align="right">
                                    {usedCredits ? usedCredits.EMERGENCY ?? 0 : ''}
                                </TableCell>
                            </TableRow>
                            {gender === 'FEMALE' &&
                                <TableRow>
                                    <TableCell>Maternity Leave</TableCell>
                                    <TableCell>
                                        <TextField InputLabelProps={{ shrink: true }}
                                                   InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                                   type="number"
                                                   value={leaves.maternityLeave}
                                                   inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                                   onChange={(e) => updateField("maternityLeave", parseInt(e.target.value))}
                                                   fullWidth
                                                   variant="standard" />
                                    </TableCell>
                                    <TableCell align="right">
                                        {usedCredits ? usedCredits.MATERNITY ?? 0 : ''}
                                    </TableCell>
                                </TableRow>
                            }
                            {gender === 'MALE' &&
                                <TableRow>
                                    <TableCell>Paternity Leave</TableCell>
                                    <TableCell>
                                        <TextField InputLabelProps={{ shrink: true }}
                                                   InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                                   type="number"
                                                   value={leaves.paternityLeave}
                                                   inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                                   onChange={(e) => updateField("paternityLeave", parseInt(e.target.value))}
                                                   fullWidth
                                                   variant="standard" />
                                    </TableCell>
                                    <TableCell align="right">
                                        {usedCredits ? usedCredits.PATERNITY ?? 0 : ''}
                                    </TableCell>
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell>Parental Leave</TableCell>
                                <TableCell>
                                    <TextField InputLabelProps={{ shrink: true }}
                                               InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                               type="number"
                                               value={leaves.parentalLeave}
                                               inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                               onChange={(e) => updateField("parentalLeave", parseInt(e.target.value))}
                                               fullWidth
                                               variant="standard" />
                                </TableCell>
                                <TableCell align="right">
                                    {usedCredits ? usedCredits.PARENTAL ?? 0 : ''}
                                </TableCell>
                            </TableRow>
                            {gender === 'FEMALE' &&
                                <>
                                    <TableRow>
                                        <TableCell>Special Leave for Women (SLW)</TableCell>
                                        <TableCell>
                                            <TextField InputLabelProps={{ shrink: true }}
                                                       InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                                       type="number"
                                                       value={leaves.specialWomanLeave}
                                                       inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                                       onChange={(e) => updateField("specialWomanLeave", parseInt(e.target.value))}
                                                       fullWidth
                                                       variant="standard" />
                                        </TableCell>
                                        <TableCell align="right">
                                            {usedCredits ? usedCredits.SLW ?? 0 : ''}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Leave for victims of violence against women</TableCell>
                                        <TableCell>
                                            <TextField InputLabelProps={{ shrink: true }}
                                                       InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                                                       type="number"
                                                       value={leaves.violenceAgainstWomanLeave}
                                                       inputProps={{ min: 0, pattern: "[0-9]*", style: { textAlign: 'right' }}}
                                                       onChange={(e) => updateField("violenceAgainstWomanLeave", parseInt(e.target.value))}
                                                       fullWidth
                                                       variant="standard" />
                                        </TableCell>
                                        <TableCell align="right">
                                            {usedCredits ? usedCredits.VAW ?? 0 : ''}
                                        </TableCell>
                                    </TableRow>
                                </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {data?.id && <>
                <Divider textAlign="center" style={{marginTop: "100px", fontWeight: "bold"}}>History</Divider>
                <Box component="form">
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell>From</TableCell>
                                    <TableCell>To</TableCell>
                                    <TableCell align="right">No. of Days</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leaveHistory?.map((history, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{LeaveTypes[history.type]}</TableCell>
                                        <TableCell>{history.fromDate}</TableCell>
                                        <TableCell>{history.toDate}</TableCell>
                                        <TableCell align="right">{history.noOfDays}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </>}
            {!readOnly &&
                <AppBar position="fixed" color="inherit" sx={{ p: 1, top: 'auto', bottom: 0 }}>
                    <Box sx={{ pl: 8, display: 'flex', justifyContent: 'center', minHeight: 40 }}>
                        <Button onClick={save} variant="contained" startIcon={<SaveIcon />} sx={{ ml: 1, minWidth: 140 }}>
                            Save
                        </Button>
                    </Box>
                </AppBar>
            }
        </React.Fragment>
    );
}