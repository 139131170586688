export const LeaveTypes = {
    VACATION: "Annual Leave",
    SICK: "Sick",
    EMERGENCY: "Emergency",
    MATERNITY: "Maternity",
    PATERNITY: "Paternity",
    SLW: "Special Leave for Women",
    VAW: "Violence Against Women",
    INDEFINITE: "Indefinite Leave",
};

export const AdminOrderTypes = {
    TRANSFER: "Transfer Order",
    PROMOTION: "Promotion",
    DEMOTION: "Demotion",
    SERVICE_RECOMMENCE: "Service Recommence",
    RESIGNATION: "Resignation",
    TERMINATION: "Termination",
    AWOL: "AWOL",
    REGULARIZATION: "Regularization",
};

export const AdminOrderTypeIds = {
    TRANSFER: "TRANSFER",
    PROMOTION: "PROMOTION",
    DEMOTION: "DEMOTION",
    SERVICE_RECOMMENCE: "SERVICE_RECOMMENCE",
    RESIGNATION: "RESIGNATION",
    TERMINATION: "TERMINATION",
    AWOL: "AWOL",
    REGULARIZATION: "REGULARIZATION",
};

export const SoaTransactionTypes = {
    SETTLEMENT: "Settlement of Account",
    DEATH_BENEFITS_COMPUTATION: "Computation of Death Benefits",
}
