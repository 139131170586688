import React, { useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import AppBar from '@mui/material/AppBar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Title from '../../../components/Title';
import { getAdminOrder, saveAdminOrder } from '../../../services/admin-orders';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdminOrderTypeIds, AdminOrderTypes } from '../../../constants/enum-types';
import { getAllBranches } from '../../../services/branches';
import { getAllAreas } from '../../../services/areas';
import { getAllRegions } from '../../../services/regions';
import { getAllDivisions } from '../../../services/divisions';
import { getAllPositions } from '../../../services/positions';
import { getAllDepartments } from '../../../services/depratments';
import ContractDetailsV2 from '../Employees/contract-details-v2';
import Divider from '@mui/material/Divider';
import LoadingSpinner from '../../../components/LoadingSpinner';
import MessageDialog from '../../../components/MessageDialog';
import EmployeeNameLookup from '../../../components/EmployeeNameLookup';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const emptyAdminOrder = {
    type: '',
    effectiveDate: null,
    effectiveDateMoment: null,
    employee: null,
}

export default function AdminOrder() {
    const { id: viewIdParam } = useParams();
    const [id] = useState(viewIdParam && viewIdParam !== 'add-new' ? viewIdParam : undefined);
    const navigate = useNavigate();
    const [adminOrder, setAdminOrder] = useState(emptyAdminOrder);
    const [readOnly, setReadOnly] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');

    const [currentEmployment, setCurrentEmployment] = useState({});
    const [newEmployment, setNewEmployment] = useState({});
    const [branches, setBranches] = useState([]);
    const [areas, setAreas] = useState([]);
    const [regions, setRegions] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [positions, setPositions] = useState([]);

    const onInit = async () => {
        await Promise.all([
            getAllBranches({ sort: 'code' }).then((res) => setBranches(res.data)),
            getAllAreas({ sort: 'name' }).then((res) => setAreas(res.data)),
            getAllRegions({ sort: 'name' }).then((res) => setRegions(res.data)),
            getAllDivisions({ sort: 'name' }).then((res) => setDivisions(res.data)),
            getAllPositions({ sort: 'name' }).then((res) => setPositions(res.data)),
            getAllDepartments({ sort: 'name' }).then(res => setDepartments(res.data)),
        ])
        if (!id) return;

        setLoading(true);
        getAdminOrder({ id }).then((res) => {
            setReadOnly(true);
            const data = res.data;
            setCurrentEmployment({
                positionData: data.oldPosition,
                positionAlias: data.oldPositionAlias,
                type: data.oldEmploymentType,
                idNo: data.oldIdNo,
                managedDepartmentDataList: data.oldPosition?.type === 'DEPARTMENT_MANAGER' && data.oldDepartment ? [data.oldDepartment] : [],
                departmentDataList: data.oldPosition?.type !== 'DEPARTMENT_MANAGER' && data.oldDepartment ? [data.oldDepartment] : [],
                managedBranchDataList: data.oldPosition?.type === 'BRANCH_MANAGER' && data.oldBranch ? [data.oldBranch] : [],
                branchDataList: data.oldPosition?.type !== 'BRANCH_MANAGER' && data.oldBranch ? [data.oldBranch] : [],
                managedAreaDataList: data.oldArea ? [data.oldArea] : [],
                managedRegionDataList: data.oldRegion ? [data.oldRegion] : [],
                managedDivisionDataList: data.oldDivision ? [data.oldDivision] : [],
            });
            setNewEmployment({
                positionData: data.newPosition,
                positionAlias: data.newPositionAlias,
                type: data.newEmploymentType,
                idNo: data.newIdNo,
                managedDepartmentDataList: data.newPosition?.type === 'DEPARTMENT_MANAGER' && data.newDepartment ? [data.newDepartment] : [],
                departmentDataList: data.newPosition?.type !== 'DEPARTMENT_MANAGER' && data.newDepartment ? [data.newDepartment] : [],
                managedBranchDataList: data.newPosition?.type === 'BRANCH_MANAGER' && data.newBranch ? [data.newBranch] : [],
                branchDataList: data.newPosition?.type !== 'BRANCH_MANAGER' && data.newBranch ? [data.newBranch] : [],
                managedAreaDataList: data.newArea ? [data.newArea] : [],
                managedRegionDataList: data.newRegion ? [data.newRegion] : [],
                managedDivisionDataList: data.newDivision ? [data.newDivision] : [],
                ecola: data.newEcola,
                salary: data.newSalary,
            });
            setAdminOrder({
                ...data,
                effectiveDateMoment: data.effectiveDate ? moment(data.effectiveDate) : null,
            });
            setLoading(false);
        });
    };

    useLayoutEffect(() => {
        onInit();
    }, [id]);

    const onEmploymentInfoChange = (employment) => {
        if (employment) {
            const changed = {
                newPosition: employment.positionData,
                newDepartment: employment.managedDepartmentDataList?.[0] ?? employment.departmentDataList?.[0],
                newBranch: employment.managedBranchDataList?.[0] ?? employment.branchDataList?.[0],
                newArea: employment.managedAreaDataList?.[0],
                newRegion: employment.managedRegionDataList?.[0],
                newDivision: employment.managedDivisionDataList?.[0],
                newSalary: employment.salary,
                newEcola: employment.ecola,
                newPositionAlias: employment.positionAlias,
                newEmploymentType: employment.type,
            };
            setAdminOrder(o => ({ ...o, ...changed }));
        }
    };

    const save = () => {
        setLoading(true);

        const payload = { ...adminOrder };
        payload.oldIdNo = currentEmployment.idNo;
        payload.oldPosition = currentEmployment.positionData;
        payload.oldPositionAlias = currentEmployment.positionAlias;
        payload.oldEmploymentType = currentEmployment.type;
        payload.oldSalary = currentEmployment.salary;
        payload.oldEcola = currentEmployment.ecola;

        if (payload.newDepartment)
            payload.oldDepartment = currentEmployment.managedDepartmentDataList?.[0] ?? currentEmployment.departmentDataList?.[0];
        if (payload.newBranch)
            payload.oldBranch = currentEmployment.managedBranchDataList?.[0] ?? currentEmployment?.branchDataList?.[0];
        if (payload.newArea)
            payload.oldArea = currentEmployment.managedAreaDataList?.[0];
        if (payload.newRegion)
            payload.oldRegion = currentEmployment.managedRegionDataList?.[0];
        if (payload.newDivision)
            payload.oldDivision = currentEmployment.managedDivisionDataList?.[0];

        saveAdminOrder({ adminOrder: payload }).then((res) => {
            setLoading(false);
            setAdminOrder(res.data);
            setReadOnly(true);
            navigate(`/transactions/admin-orders/${res.data.id}`, { replace: true });
            navigate(0);
        }).catch((err) => {
            setLoading(false);
            popUpMessage(false, err.response.data);
        });
    };

    const updateField = (fieldName, value) => {
        let dirty = {};
        dirty[fieldName] = (typeof value === 'string') ? value.toUpperCase() : value;
        setAdminOrder(b => ({
            ...b,
            ...dirty
        }));

        if (fieldName === 'employee') {
            setCurrentEmployment(value?.employmentInfoData ?? {});
        }
    };

    useLayoutEffect(() => {
        if (readOnly)
            return;

        setNewEmployment({
            positionData: adminOrder?.employee?.employmentInfoData?.positionData
        });
    }, [adminOrder.type, adminOrder.employee]);

    const popUpMessage = (isOk, message) => {
        setSuccess(isOk);
        setMessage(message?.toString());
        setOpenMessage(true);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack sx={{ ml: -5, mt: -5 }} direction="row" alignItems="center" spacing={1}>
                <IconButton size="large" component={Link} onClick={() => navigate(-1)}>
                    <ArrowBackIcon fontSize="inherit"/>
                </IconButton>
            </Stack>
            <Title>Admin Order</Title>
            <Box sx={{ mt: 5 }} style={{ paddingBottom: 20 }} component="form" noValidate autoComplete="off">
                <Grid container spacing={3}>
                    <Grid item sm={6} xs={12}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel required shrink={true}>Transaction Type</InputLabel>
                            <Select readOnly={readOnly}
                                    disableUnderline={readOnly}
                                    value={adminOrder.type}
                                    onChange={(e) => updateField("type", e.target.value)}>
                                {Object.keys(AdminOrderTypes).sort().map(key => (
                                    <MenuItem key={key} value={key}>{AdminOrderTypes[key]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={0}/>
                    <Grid item sm={6} xs={12}>
                        <DatePicker label="Effective Date"
                                    readOnly={readOnly}
                                    slotProps={{
                                        textField: { variant: 'standard',
                                            required: true,
                                            InputLabelProps: {shrink: true},
                                            InputProps: {disableUnderline: readOnly},
                                            onChange: (newValue) => {
                                                updateField('effectiveDateMoment', newValue);
                                                updateField('effectiveDate', moment(newValue).format('MM/DD/YYYY'));
                                            },
                                        },
                                    }}
                                    onChange={(newValue) => {
                                        updateField('effectiveDateMoment', newValue);
                                        updateField('effectiveDate', moment(newValue).format('MM/DD/YYYY'));
                                    }}
                                    value={adminOrder.effectiveDateMoment}
                                    format="MM/DD/YYYY"
                                    fullWidth />
                    </Grid>
                    <Grid item sm={6} xs={0}/>
                    <Grid item sm={6} xs={12}>
                        <EmployeeNameLookup
                            readOnly={readOnly}
                            value={adminOrder.employee}
                            onChange={value => updateField('employee', value)}
                            showRegularEmployeeIndicator={true}
                        />
                    </Grid>
                    <Grid item sm={6} xs={0}/>
                    {adminOrder.employee && <>
                        <Grid item sm={6} xs={12}>
                            ID Number: {currentEmployment.idNo}
                        </Grid>
                        <Grid item sm={6}>
                            Position: {currentEmployment.positionData?.name}
                            {currentEmployment.positionAlias ? ` (${currentEmployment.positionAlias})` : ''}
                        </Grid>
                        <Grid item sm={6}>
                            Employment: {currentEmployment.type}
                        </Grid>
                        <Grid item sm={6}>
                            {currentEmployment.positionData?.type === 'DIVISION_MANAGER' &&
                                <>Managed
                                    Division: {currentEmployment.managedDivisionDataList?.map(d => d.name).join(', ')}</>
                            }
                            {currentEmployment.positionData?.type === 'REGION_MANAGER' &&
                                <>Managed
                                    Region: {currentEmployment.managedRegionDataList?.map(r => r.name).join(', ')}</>
                            }
                            {currentEmployment.positionData?.type === 'AREA_MANAGER' &&
                                <>Managed
                                    Area: {currentEmployment.managedAreaDataList?.map(a => a.name).join(', ')}</>
                            }
                            {currentEmployment.positionData?.type === 'BRANCH_MANAGER' &&
                                <>Managed
                                    Branch: {currentEmployment.managedBranchDataList?.map(b => `${b.code} - ${b.name}`).join(', ')}</>
                            }
                            {currentEmployment.positionData?.type === 'DEPARTMENT_MANAGER' &&
                                <>Managed
                                    Department: {currentEmployment.managedDepartmentDataList?.map(d => `${d.code} - ${d.name}`).join(', ')}</>
                            }
                            {!currentEmployment.positionData?.type && (!currentEmployment.positionData?.officeType || currentEmployment.positionData?.officeType === 'OPERATION') &&
                                <>Branch: {currentEmployment.branchDataList?.map(b => `${b.code} - ${b.name}`).join(', ')}</>
                            }
                            {!currentEmployment.positionData?.type && currentEmployment.positionData?.officeType === 'ADMIN' &&
                                <>Department: {currentEmployment.departmentDataList?.map(d => `${d.code} - ${d.name}`).join(', ')}</>
                            }
                        </Grid>
                        {[AdminOrderTypeIds.PROMOTION, AdminOrderTypeIds.DEMOTION, AdminOrderTypeIds.SERVICE_RECOMMENCE].includes(adminOrder?.type) && <>
                            <Grid item sm={6}>
                                Salary: {currentEmployment.salary?.toFixed(2)}
                            </Grid>
                            <Grid item sm={6}>
                                ECOLA: {currentEmployment.ecola?.toFixed(2)}
                            </Grid>
                        </>}
                    </>}
                </Grid>
            </Box>

            {(adminOrder.employee && adminOrder.type) && <>
                <Divider textAlign="center" style={{ margin: "20px 0 30px", fontWeight: "bold" }}>
                    {AdminOrderTypes[adminOrder.type]} Details
                </Divider>

                <ContractDetailsV2
                    data={newEmployment}
                    branches={branches} areas={areas} regions={regions} divisions={divisions} departments={departments}
                    positions={positions}
                    popUpMessage={popUpMessage} setLoading={setLoading}
                    readonly={!adminOrder.employee || readOnly}
                    positionVisible={adminOrder.type === AdminOrderTypeIds.PROMOTION || adminOrder.type === AdminOrderTypeIds.DEMOTION}
                    officeAssignmentVisible={adminOrder.type === AdminOrderTypeIds.TRANSFER || adminOrder.type === AdminOrderTypeIds.PROMOTION || adminOrder.type === AdminOrderTypeIds.DEMOTION}
                    employmentInfoVisible={adminOrder.type === AdminOrderTypeIds.PROMOTION || adminOrder.type === AdminOrderTypeIds.DEMOTION || adminOrder.type === AdminOrderTypeIds.SERVICE_RECOMMENCE}
                    idNoVisible={false}
                    startDateVisible={false}
                    onEmploymentInfoChange={onEmploymentInfoChange}
                />
            </>}

            <MessageDialog
                title={success ? 'Success' : 'Error'}
                message={message}
                open={openMessage}
                onClose={() => setOpenMessage(!openMessage)}
            />

            <LoadingSpinner open={loading}/>

            {!id &&
                <AppBar position="fixed" color="inherit" sx={{ p: 1, top: 'auto', bottom: 0 }}>
                    <Box sx={{ pl: 8, display: 'flex', justifyContent: 'center', minHeight: 40 }}>
                        {!readOnly
                            ? <Button onClick={save} variant="contained" startIcon={<SaveIcon/>}
                                      sx={{ ml: 1, minWidth: 140 }}>
                                Save
                            </Button>
                            : <Button onClick={() => setReadOnly(false)} variant="contained" startIcon={<EditIcon/>}
                                      sx={{ ml: 1, minWidth: 140 }}>
                                Edit
                            </Button>
                        }
                    </Box>
                </AppBar>
            }
        </LocalizationProvider>
    );
}
